import React,{ useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import Main from './components/Main';
import Loading from './components/Loading';

import Overlay from './panels/Overlay';
import bridge from '@vkontakte/vk-bridge';

import { setLaunchParams, setPlatform, setAttempts, setIncrement, setActiveModal, setModalName, setConfirmAll, setFirstPage, setGroupLink, setTickets, setHeals, setTasks, setTgID, setPrizes, setGameDays, setReferal, setFriendsCount, setActivePanel } from "./store/mainReducer"

import ServerConnect from './service';

import { Snackbar } from "@vkontakte/vkui";
import { Icon24DismissSubstract, Icon24CheckCircleOn } from '@vkontakte/icons';
import '@vkontakte/vkui/dist/vkui.css';
import { isIOS } from 'react-device-detect';
import OnBoardingFirst from './components/OnBoardingFirst';
import OnBoardingSecond from './components/OnBoradingSecond';
import Navbar from './components/Navbar';
import Info from './components/Info';
import Task from './components/Task';
import Prize from './components/Prize';
import Rebus from './components/Rebus';

const App = () => {
  const dispatch = useDispatch();
  const activePanel = useSelector(state => state.main.activePanel);
  const isActiveNavbar = useSelector(state => state.main.isActiveNavbar);
  const platform = useSelector(state => state.main.platform);
  function parseQuery(queryString) {
    let query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }
  let userChatId;

  useEffect(() => {
    dispatch(setActivePanel('loading'))
  }, []);

  let content = []
  if (activePanel === 'loading') {
    content.push(<Loading key='loading'/>)
  }
  else if (activePanel === 'onboarding-first') {
    content.push(<OnBoardingFirst key='onboarding-first'/>)
  }
  else if (activePanel === 'onboarding-second') {
    content.push(<OnBoardingSecond key='onboarding-second'/>)
  }
  else if (activePanel === 'main') {
    content.push(<Main  key='main'/>)
  }
  else if (activePanel === 'info') {
    content.push(<Info key='info'/>)
  }
  else if (activePanel === 'task') {
    content.push(<Task key='task'/>)
  }
  else if (activePanel === 'prize') {
    content.push(<Prize key='prize'/>)
  }
  else if (activePanel === 'rebus') {
    content.push(<Rebus key='rebus'/>)
  }
  return (
    <div className={`app ${platform}`}>
      <>
        {/* <div className={isIOS ? 'ios-help-bg' : 'not-ios-help'}></div> */}
        {content}
        <Navbar isActive={isActiveNavbar}/>
        <Overlay>
        </Overlay>
      </>
    </div>
  );
}
export default App;
