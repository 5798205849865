import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setActivePanel } from "../../store/mainReducer";
import bridge from '@vkontakte/vk-bridge';
import './style.css'
import ServerConnect from '../../service';

const Loading = ({}) => {
    const dispatch = useDispatch();
    const [classPage, setClassPage] = useState("loading-page");
    const [loadingText, setLoadingText] = useState('Спортивный Ассистент')
    useEffect(() => {
      setTimeout(() => {
        setLoadingText('Приложение в разработке');
        // setClassPage('loading-page loading-page_disable')
        //   setTimeout(() => {
        //     dispatch(setActivePanel("onboarding-first"));
        //   }, 500)
      }, 2500);
    }, []);

    return (
        <div className={`container ${classPage}`}>
            <img className='loading-page__bg' src="assets/images/loading-page-bg.png" alt="" />
            <div className='loading-page__center-block'>
              <img className='loading-page__logo' src="assets/images/sport-logo.png" alt="" />
              <p className='loading-page__text'>
                {loadingText}
              </p>
            </div>
            <div className="loading-wrapper">
                <div className="loading-block loading-page__loading-block">
                    <div className="loading-block__bar"></div>
                </div>
            </div>
        </div>
    );
}


export default Loading;